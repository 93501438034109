/**
 * IMPORTANT: This file is for dealer-franchisee app initialization.
 * do not use this file for other apps.
 * DO not import anything from the main application. this forms a stand alone
 * initialization for the dealer-franchisee app.
 *
 */
// @ts-ignore
import { Workbox } from 'https://storage.googleapis.com/workbox-cdn/releases/6.4.1/workbox-window.prod.mjs';

export async function intializeServiceWorker(serviceWorkerName: string) {
  async function flushCache(): Promise<void> {
    localStorage.setItem('flush-no-sql', 'true');

    if ('caches' in window) {
      const cacheNames = await caches.keys();
      await Promise.all(cacheNames.map(cacheName => caches.delete(cacheName)));
      console.log('All caches have been flushed.');
    } else {
      console.warn('Cache API is not supported in this browser.');
    }
  }

  function updateServiceWorker(reg, runImmediately = false) {
    if (runImmediately)
      reg.update().catch(e => {
        if (e.message?.includes('An unknown error occurred when fetching the script.')) {
          //Getting here, means that the script could not be downloaded (User's network is turned off).
          return;
        }

        //Getting here means something else happened, and we need it to pass through the normal error handler.
        throw e;
      });
    //polling for an update every 30min
    setTimeout(() => updateServiceWorker(reg, true), 30 * 60000);
  }

  if ('serviceWorker' in navigator) {
    if (navigator.serviceWorker.controller) {
      console.log(`This page is currently controlled by: ${navigator.serviceWorker.controller}`);
    } else {
      console.log('This page is not currently controlled by a service worker.');
      await flushCache();
    }

    const wb = new Workbox(serviceWorkerName ?? '/softtech-serviceworker.js');

    wb.addEventListener('installed', () => {
      console.log('service controlling');
    });
    wb.addEventListener('controlling', () => {
      console.log('service controlling');
    });

    const showSkipWaitingPrompt = async _event => {
      // Assuming the user accepted the update, set up a listener
      // that will reload the page as soon as the previously waiting
      // service worker has taken control.

      // When `event.wasWaitingBeforeRegister` is true, a previously
      // updated service worker is still waiting.
      // You may want to customize the UI prompt accordingly.

      //await flushCache();
      wb.messageSkipWaiting();
    };

    let refreshing = false;
    wb.addEventListener('controllerchange', _event => {
      console.log('controller change');
      if (refreshing) return;
      refreshing = true;
      //flushCache();
    });
    wb.addEventListener('activated', _event => {
      console.log('activated');
    });
    wb.addEventListener('activate', _event => {
      console.log('activated');
    });

    // Add an event listener to detect when the registered
    // service worker has installed but is waiting to activate.
    wb.addEventListener('waiting', event => {
      console.log('service waiting');
      showSkipWaitingPrompt(event);
    });

    wb.addEventListener('installing', event => {
      console.log('service installing');
      event.addEventListener('statechange', event => {
        console.log('service state change');
        showSkipWaitingPrompt(event);
      });
    });
    wb.addEventListener('installed', _event => {
      console.log('service installing');
    });

    wb.addEventListener('updatefound', event => {
      console.log('service update Found');
      showSkipWaitingPrompt(event);
    });
    navigator.serviceWorker.onmessage = async event => {
      // this is triggered by the service worker
      if (event.data === 'NEW SERVICE WORKER ACTIVE') {
        let serviceCacheMismatch = globalThis.isAppOutOfDate?.(globalThis.dealerServiceWorkerVersion ?? 'x') ?? true;
        let serverCacheMismatch = globalThis.isAppOutOfDate?.() ?? true;

        if (serviceCacheMismatch || serverCacheMismatch) {
          if (globalThis.reloadPageIfOutOfDate) await globalThis.reloadPageIfOutOfDate(true);
          else {
            await flushCache();
            /*
            const localMessage = serviceCacheMismatch ? 'Local Service Cache Mismatch' : '';
            const serverMessage = serverCacheMismatch ? 'Server Cache Mismatch' : '';

            const msg = `the software has been updated on the server and must now be refreshed. 

               ${localMessage} 
               
               ${serverMessage}
                              
               this page is going to reload now to attempt to get the latest version.
               
               `;
            if (globalThis.informAsync) await globalThis.informAsync?.(msg);
            else alert(msg);
            
             */
          }
          window.location.reload();
        }
      }
    };
    wb.addEventListener('fetch', event => {
      event.respondWith(
        (async () => {
          if (
            event.request.mode === 'navigate' &&
            event.request.method === 'GET' &&
            registration.waiting &&
            (await wb.clients.matchAll()).length < 2
          ) {
            wb.messageSkipWaiting();
            //We’ll use the HTTP Refresh header to refresh the page after 0 seconds
            return new Response('', {
              headers: { Refresh: '0' }
            });
          }

          return (await caches.match(event.request)) || fetch(event.request);
        })()
      );
    });

    await unregisterServiceWorkers(serviceWorkerName);

    try {
      const reg = await wb.register();
      updateServiceWorker(reg);
    } catch (e) {
      console.error('Service Worker registration failed', e);
      alert('Service Worker registration failed: cannot load software ' + (e as any).message);
      window.location.reload();
    }
  }
}

async function unregisterServiceWorkers(exceptName: string) {
  if ('serviceWorker' in navigator) {
    const registrations = await navigator.serviceWorker.getRegistrations();
    for (const registration of registrations) {
      if (registration.active?.scriptURL.includes(exceptName)) {
        console.log(`Keeping service worker: ${registration.active.scriptURL}`);
      } else {
        console.log(`Unregistering service worker: ${registration.active?.scriptURL}`);
        await registration.unregister();
      }
    }
  } else {
    console.warn('Service workers are not supported in this browser.');
  }
}
